import React from 'react';
import logo from '../../../../assets/footer-logo.svg';
import './styles.scss';

const Footer = ({ showTermsUse }) => {
    return (
        <footer className={"footer"}>
            <div className={"wrapper"}>
                <div className={"about-us"}>
                    <h5>About us</h5>
                    <p>We are a new gaming studio based in London, UK. Our mission is to create free and fun games for the casual-gaming community.</p>
                    <p className={'fun'}>Have fun!</p>
                </div>
                <div className={"logo"}>
                    <img
                        src={logo}
                        alt="logo"
                        width={229}
                        height={59}
                    />
                </div>
                <div className={"terms-use"}>
                    <p className={"apply"}>TERMS AND CONDITIONS APPLY</p>
                    <p className={"links"}>
                        <span onClick={() => showTermsUse('terms')}>Terms of Use </span>
                         |
                        <span onClick={() => showTermsUse('privacy')}> Privacy Policy</span>
                    </p>
                </div>
            </div>
            <div className={"copyright"}>
                <p>
                    ©2023 Snake Color Brake. All Rights Reserved.
                </p>
            </div>
        </footer>
    )
};

export default Footer;
