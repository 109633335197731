import React from "react";
import "./styles.scss";

const GameLoader = ({ loadingProgression }) => {
    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <div className="loading-overlay">
            <p className={"loading-percentage"}>{loadingPercentage}%</p>
            <div className="spinner">
                <div className="bubble-1"></div>
                <div className="bubble-2"></div>
            </div>
        </div>
    )
};

export default GameLoader;
