import  React from 'react';
import PropTypes from 'prop-types';
import './spinnerDot.scss';

const SpinnerDot = (props) => (
    <div className='spinner-dot'>
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="spinner"
             className="svg-inline--fa fa-spinner fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512">
            <path fill={props.color}
                  d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"/>
        </svg>
    </div>
);

SpinnerDot.propTypes = {
    color: PropTypes.string.isRequired
};

export default SpinnerDot;


