import {environment} from "App/Helpers/enviroments";

const env = environment();
const buildUrl = env === 'production' ? 'https://snakecolorbrake.com/game/' : 'https://test-game-worker.pages.dev/build/'
export const buildVersion = '1.0.8'

const unityConfig = {
    loaderUrl: `${buildUrl}WebGL_${buildVersion}.loader.js`,
    frameworkUrl: `${buildUrl}WebGL_${buildVersion}.framework.js.unityweb`,
    dataUrl: `${buildUrl}WebGL_${buildVersion}.data.unityweb`,
    codeUrl: `${buildUrl}WebGL_${buildVersion}.wasm.unityweb`,
    productName: "Snake Color Brake",
    companyName: "Reyo Media Cyprus",
    productVersion: buildVersion,
}

export default unityConfig;