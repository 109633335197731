import { environment } from "../Helpers/enviroments";
import UrlParams from "App/Helpers/UrlParams";

class SharpDataStarService {
    /**
     * get page config data to call sharpstar
     * @param sharpstarBaseUrl
     * @param source
     * @param campaignId
     * @param abortController
     */
    constructor(sharpstarBaseUrl, source, campaignId, abortController) {
        this.sharpstarBaseUrl = sharpstarBaseUrl;
        this.source = source;
        this.campaignId = campaignId;

        if (abortController) {
            this.signal = abortController.signal;
        }
        environment() !== 'production' && console.info(
            '%cInit SharpStar Data Service',
            'background: #CACE91; color: #010204; font-weight: 900'
        );
    }

    get getOfferUrl() {
        return this.sharpstarBaseUrl + 'offer';
    }

    get getCreateLeadUrl() {
        return this.sharpstarBaseUrl + 'lead/create';
    }

    get getCreatePushLeadUrl() {
        return this.sharpstarBaseUrl + 'create-push-lead';
    }

    get getaddPushDeviceUrl() {
        return this.sharpstarBaseUrl + 'add-push-device';
    }

    get getaddContactInfoUrl() {
        return this.sharpstarBaseUrl + 'add-contact-info';
    }

    /**
     * request getOffers from SharpStar
     * @param hash
     * @param isLead
     * @param rid
     * @returns {Promise<Response>}
     */
    getOffers(hash, isLead, rid) {
        const url = new URL(this.getOfferUrl);
        const params = {
            rid: rid,
            is_lead: isLead,
            source: this.source,
            campaign_id: this.campaignId,
            refid1: UrlParams.refid1
        };

        if (hash) {
            params.hash = hash;
        }

        url.search = new URLSearchParams(params);

        return fetch(url, {signal: this.signal});
    }

    /**
     * create lead with email
     * @param email
     * @param name
     * @param offerId
     * @param extraData
     * @returns {Promise<Response>}
     */
    createLead(email, name, offerId, extraData) {
        const params = {
            campaign_id: this.campaignId,
            rid: UrlParams.rid,
            source: this.source,
            refid1: UrlParams.refid1,
            refid2: UrlParams.refid2,
            refid3: UrlParams.refid3,
            full_name: name,
            email: email,
            offer_id: offerId
        };
        if(extraData !== null) {
            params.extra_data = extraData;
        }

        const data = this.toUrlSearchParams(params);

        return fetch(this.getCreateLeadUrl, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            signal: this.signal
        });
    }

//{'content-type': 'application/x-www-form-urlencoded'},

    /**
     * create lead with push notifications
     * @param device_id
     * @param offerId
     * @param rid
     * @returns {Promise<Response>}
     */
    createPushLead(device_id, offerId, rid) {
        const params = {
            refid1: UrlParams.refid1,
            refid2: UrlParams.refid2,
            refid3: UrlParams.refid3,
            campaign_id: this.campaignId,
            source: this.source,
            device_id: device_id,
            offer_id: offerId,
            rid: rid,
        };
        const data = this.toUrlSearchParams(params);

        return fetch(this.getCreatePushLeadUrl, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            signal: this.signal
        });
    }

    /**
     * add device to push notifications to an exist lead
     * @param device_id
     * @param hash
     * @param offerId
     * @returns {Promise<Response>}
     */
    addPushDevice(device_id, hash, offerId) {
        const params = {
            campaign_id: this.campaignId,
            rid: UrlParams.rid,
            source: this.source,
            refid1: UrlParams.refid1,
            refid2: UrlParams.refid2,
            refid3: UrlParams.refid3,
            device_id: device_id,
            hash: hash,
            offer_id: offerId
        };
        const data = this.toUrlSearchParams(params);
        return fetch(this.getaddPushDeviceUrl, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            signal: this.signal
        });
    }

    /**
     * add email registration to an exist lead
     * @param name
     * @param email
     * @param hash
     * @param offerId
     * @param extraData
     * @returns {Promise<Response>}
     */
    addContactInfo(name, email, hash, offerId, extraData) {
        const params = {
            campaign_id: this.campaignId,
            rid: UrlParams.rid,
            source: this.source,
            refid1: UrlParams.refid1,
            refid2: UrlParams.refid2,
            refid3: UrlParams.refid3,
            hash: hash,
            full_name: name,
            email: email,
            offer_id: offerId,
        };

        if(extraData !== null) {
            params.extra_data = extraData;
        }

        const data = this.toUrlSearchParams(params);

        return fetch(this.getaddContactInfoUrl, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            signal: this.signal
        });
    }

    /**
     * get data object and return it as URLSearchParams
     * @param dataObject
     */
    toUrlSearchParams(dataObject) {
        const data = new URLSearchParams();
        const keys = Object.keys(dataObject);
        const values = Object.values(dataObject);
        for (let i = 0; i < keys.length; i++) {
            data.append(keys[i], values[i]);
        }
        return data;
    }
}

export default SharpDataStarService;
