import React from "react";
import Hero from "./components/Hero";
import About from "./components/About";
import Features from "./components/Features";
import Advantages from "./components/Advantages";
import Tutorial from "./components/Tutorial";
import Footer from "./components/Footer";

const Landing = ({ onPlayBtnClick, showTermsUse }) => {
    return (
        <>
            <Hero onPlayHandler={onPlayBtnClick} />
            <About onPlayHandler={onPlayBtnClick} />
            <Features />
            <Advantages />
            <Tutorial />
            <section className={"container colored"}>
              <div className={"wrapper body1 tip"}>
                If you’re up for a challenge, have lightning-quick reflexes, and you’re looking for your next game addiction, the leaderboards at Snake Color Brake are waiting for you!
              </div>
            </section>
            <Footer showTermsUse={showTermsUse} />
        </>
    )
}

export default Landing;