import ReactGA from "react-ga4";
import * as Sentry from "@sentry/browser";
import { environment } from "../../Helpers/enviroments";

export default class GoogleAnalytics {
    constructor(enabled, landingName, trackingId, gaOptions = {}, gtagOptions = {}) {
        this.landingName = landingName;
        this.enabled = enabled;

        this.init(trackingId, gaOptions, gtagOptions);
    }

    client() {
        return ReactGA;
    }

    init(trackingId, gaOptions, gtagOptions) {
        environment() !== 'production' && console.info(
            '%cstart Init Google Analytics 4',
            'background: #F9CCC8; color: #2C2826; font-weight: 900'
        );

        if (!this.enabled) {
            return;
        }
        ReactGA.initialize([{ trackingId, gaOptions, gtagOptions }]);
    }

    pwaLaunched(pageType) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: pageType,
            action: `${pageType} Loaded`,
            label: this.landingName,
            nonInteraction: true
        })
    }

    buttonClicked(eventName) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: eventName,
            label: this.landingName
        })
    }

    pwaButtonClicked(pageType, buttonName) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: `${pageType} - ${buttonName} Button Click`,
            label: this.landingName
        })
    }

    pwaInstalled() {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'PWA',
            action: 'PWA Install Success',
            label: this.landingName,
            nonInteraction: true
        })
    }

    pwaInstallPromptAnswer(answer) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: 'PWA Install Prompted '+ answer,
            label: this.landingName
        })
    }

    sendPageView(noneURLPath = '') {
        if (!this.enabled) {
            return;
        }

        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + noneURLPath + window.location.search
        });
    }

    adBlock(isEnabled) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'adBlock',
            action: JSON.stringify(isEnabled),
            label: this.landingName,
            nonInteraction: true
        })
    }

    initialDisplayMode() {
        if (!this.enabled) {
            return;
        }

        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        ReactGA.event({
            category: 'display mode',
            label: this.landingName,
            action: `Initial display mode - ${isStandalone ? 'PWA' : 'Browser'} Display`,
            nonInteraction: true
        })
    }

    displayModeChangeTracking() {
        if (!this.enabled) {
            return;
        }

        const mediaQuery = window.matchMedia('(display-mode: standalone)');

        try {
            mediaQuery.addEventListener('change', (evt) => {
                ReactGA.event({
                    category: 'display mode',
                    label: this.landingName,
                    action: `PWA display mode changed to ${evt.matches ? 'PWA Display' : 'Browser Display'}`,
                })
            });
        } catch (e1) {
            try {
                mediaQuery.addEventListener('change', (evt) => {
                    ReactGA.event({
                        category: 'display mode',
                        label: this.landingName,
                        action: `PWA display mode changed to ${evt.matches ? 'PWA Display' : 'Browser Display'}`,
                    })
                });
            } catch (e2) {
                Sentry.captureException(e2);
            }
        }
    }

    pushNotificationPermission(permission) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: 'push notification permission: ' + permission,
            label: this.landingName
        })
    }

    customEvent(category, action) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: category,
            action: action,
            label: this.landingName
        })
    }
}
