/**
 * Check if container DOM element is iframe element or it has iframe element
 *
 * @param element: <HTMLElement>(required)
 *
 * @return boolean
 */
const hasAdIframeElement = (element) => {
    if (element.tagName === 'IFRAME') {
        // If the element is an iframe, return true
        return true;
    } else {
        // Otherwise, check each child element recursively
        for (let i = 0; i < element.childElementCount; i++) {
            if (hasAdIframeElement(element.children[i])) {
                // If any child element has an iframe element, return true
                return true;
            }
        }
    }
    // If no iframe element is found, return false
    return false;
}

export default hasAdIframeElement;
