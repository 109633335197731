import React, { useEffect } from 'react';
import './styles.scss';

const AdBlockerModal = ({ adBlockOn }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => document.body.style.overflow = 'unset';
    }, []);

    const onClickHandler = () => {
        // todo: refactor check adBlock function and call it here to check if game phase is "game"

        if (adBlockOn) {
            window.location.reload();
        }
    }

    return (
      <div className={"modal-adblock_overlay"}>
        <div className={"modal-adblock_content"}>
          <div className={"modal-adblock_title"}>Ad-block Detected!</div>
            <p className={"modal-adblock_body"}>We detected that you are using an ad blocker,
                <br/>
                <span className={"yellow"}>please turn it off.</span>
            </p>
            <div onClick={onClickHandler} className={"modal-adblock_button"}>
                Yes, Ad-block is off
            </div>
        </div>
      </div>
    )
};

export default AdBlockerModal;