import React from 'react';
import logo from '../../../../assets/logo-main.svg';
import img320 from '../../../../assets/hero-320.png';
import img414 from '../../../../assets/hero-414.png';
import img576 from '../../../../assets/hero-576.png';
import img768 from '../../../../assets/hero-768.png';
import img1024 from '../../../../assets/hero-1024.png';
import img1920 from '../../../../assets/hero-1920.png';
import './styles.scss';

const Hero = ({ onPlayHandler }) => {
    return (
        <section className={"wrapper hero"}>
            <div className={"hero-message"}>
                <img src={logo} className={"logo"} alt="logo" width={433} height={110}/>
                <h1>Get hooked on your next arcade addiction with Snake Color Brake.</h1>
                <button
                    className={"button"}
                    onClick={() => onPlayHandler()}>
                    Play
                </button>
            </div>
            <picture className={"phone-screens"}>
                    <source
                        srcSet={img1920}
                        media="(min-width: 1320px)"
                    />
                    <source
                        srcSet={img1024}
                        media="(min-width: 1024px)"
                    />
                    <source
                        srcSet={img768}
                        media="(min-width: 768px)"
                    />
                    <source
                        srcSet={img576}
                        media="(min-width: 576px)"
                    />
                    <source
                        srcSet={img414}
                        media="(min-width: 414px)"
                    />
                    <source
                        srcSet={img320}
                        media="(min-width: 320px)"
                    />
                    <img
                        src={img1920}
                        alt="Four phones with different game stage screens"
                    />
                </picture>
        </section>
    )
};

export default Hero;