import React from "react";
import phone1920 from "../../../../assets/about-phone-1920.png";
import phone768 from "../../../../assets/about-phone-768.png";
import phone320 from "../../../../assets/about-phone-320.png";
import './styles.scss';

const About = ({ onPlayHandler }) => {
  return (
      <section className={"container colored"}>
          <div className={"wrapper about"}>
              <picture className={"phone"}>
                  <source
                      srcSet={phone1920}
                      media="(min-width: 1024px)"
                  />
                  <source
                      srcSet={phone768}
                      media="(min-width: 414px)"
                  />
                  <source
                      srcSet={phone320}
                      media="(min-width: 320px)"
                  />
                  <img
                      src={phone1920}
                      alt="Phone with game screen"
                  />
              </picture>
              <div className={"about-info"}>
                  <h2>About the
                      <span className={"highlighted"}> game</span>
                  </h2>
                  <div className={"description"}>
                      <p className={"body2"}>A fun, fast-paced online game, you control a snake of balls as it moves across the screen, destroying blocks and growing in size the more balls you swipe.</p>
                      <br/>
                      <p className={"body2"}>The better you are, the bigger the snake grows and the harder blocks you can destroy with your power-ups. Sound simple? It’s not. See if you’ve got the reflexes it takes to master Snake Color Brake.</p>
                  </div>
                  <button
                      className={"button"}
                      onClick={() => onPlayHandler()}>
                      Play
                  </button>
              </div>
          </div>
      </section>
  )
};

export default About;