import { useCallback, useEffect, useState } from "react";
import { useAdService } from "App/Services/AdService";
import { gameContainerBg } from "../../../../config";
import { shouldDisplayPreroll } from "../../../../helpers";

/**
 * Send design event to game analytics from Unity Context
 *
 * @param gameAnalytics: new () => Class (initialized GameAnalytics Class instance)
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalytics = (gameAnalytics, addEventListener, removeEventListener) => {
    const handleSendAnalytics = useCallback((eventName, value = undefined, fieldsJson = undefined) => {
        gameAnalytics.sendDesignEvent(eventName, value, fieldsJson);
    }, []);

    useEffect(() => {
        addEventListener("SendAnalyticsEvent", handleSendAnalytics);

        return () => {
            removeEventListener("SendAnalyticsEvent", handleSendAnalytics);
        };

    }, [addEventListener, removeEventListener, handleSendAnalytics]);
}

/**
 * Send design error event to game analytics from Unity Context
 *
 * @param gameAnalytics: new () => Class (initialized GameAnalytics Class instance)
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalyticsError = (gameAnalytics, addEventListener, removeEventListener) => {
    const handleSendAnalyticsError = useCallback((errorType, message) => {
        gameAnalytics.sendErrorEvent(errorType, message);
    }, []);

    useEffect(() => {
        addEventListener("SendErrorEvent", handleSendAnalyticsError);

        return () => {
            removeEventListener("SendErrorEvent", handleSendAnalyticsError);
        };

    }, [addEventListener, removeEventListener, handleSendAnalyticsError]);
}

/**
 * hook that fires when game loaded,
 * sets ad banner visibility and
 * update ad banner content (you can add any additional logic into handleLevelLoaded)
 *
 * @param elementRef: RefObject<HTMLDivElement>
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useLevelLoaded = (elementRef, addEventListener, removeEventListener) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleLevelLoaded = useCallback((attempt) => {
        console.log('useLevelLoaded', {attempt});

        updateAd('snakecolorbrake-com_320x50_HP', elementRef.current, attempt);
        setBannerVisible(elementRef.current, true);
    }, [elementRef, updateAd, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnLevelLoaded', handleLevelLoaded);

        return () => {
            console.log('clear effect LevelLoaded');
            removeEventListener('OnLevelLoaded', handleLevelLoaded);
        };
    }, [addEventListener, removeEventListener, handleLevelLoaded]);
}

/**
 * hook that fires when game level started and
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param refHpBannerBottom: RefObject<HTMLDivElement>
 * @param refGameplayBannerBottom: RefObject<HTMLDivElement>
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useLevelStarted = (refHpBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener) => {
    const { updateAd, setBannerVisible } = useAdService();
    
    const handleLevelStarted = useCallback((attempt) => {
        console.log('useLevelStarted', {attempt});

        updateAd('snakecolorbrake-com_320x50_gameplay', refGameplayBannerBottom.current, attempt);
        setBannerVisible(refGameplayBannerBottom.current, true);
        setBannerVisible(refHpBannerBottom.current, false);
    }, [refHpBannerBottom, refGameplayBannerBottom, updateAd, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnLevelStarted', handleLevelStarted);

        return () => {
            console.log('clear effect LevelStarted');
            removeEventListener('OnLevelStarted', handleLevelStarted);
        };
    }, [addEventListener, removeEventListener, handleLevelStarted]);
}

/**
 * hook that fires when game level ended,
 * gets current game level as parameter and
 * shows ad video preroll (you can add any additional logic into handleLevelEnded)
 *
 * @param setShowPushModal: () => void (sets showPushModal state)
 * @param pushPrompt: { startLevel: number, total: number }
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useLevelEnded = (setShowPushModal, pushPrompt, addEventListener, removeEventListener) => {
    const [startPreroll, setStartPreroll] = useState(0);
    const { showPreroll, adInterval } = useAdService();

    const handleLevelEnded = useCallback((level, attempt) => {
        console.log('useLevelEnded', {level, attempt});

        if (shouldDisplayPreroll(attempt, adInterval)) {
            setStartPreroll((prev) => prev + 1);
            showPreroll(level);
        }

        if (!!pushPrompt) {
            const { startLevel, total } = pushPrompt;
            const endShowPush = startLevel - 1 + total;

            if (attempt >= startLevel - 1 && attempt < endShowPush && !localStorage.getItem('push-allowed')) {
                setShowPushModal(true);
            }
        }
    }, [adInterval, pushPrompt, setShowPushModal, showPreroll]);

    useEffect(() => {
        addEventListener('OnLevelEnded', handleLevelEnded);

        return () => {
            console.log('clear effect LevelEnded');
            removeEventListener('OnLevelEnded', handleLevelEnded)
        };

    }, [addEventListener, removeEventListener, handleLevelEnded]);

    return startPreroll;
}

/**
 * hook that fires when game result screen is showing,
 * handler gets current game attempt as parameter and handles
 * result screen ad banners display
 *
 * @param centerBannerRef: RefObject<HTMLDivElement>
 * @param bottomRestartBannerRef: RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef: RefObject<HTMLDivElement>
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useResultShow = (centerBannerRef, bottomRestartBannerRef, bottomGameplayBannerRef, addEventListener, removeEventListener) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnResultShow = useCallback((attempt) => {
        console.log('useResultShow', {attempt});

        updateAd('snakecolorbrake-com_300x250_restart', centerBannerRef.current, attempt);
        updateAd('snakecolorbrake-com_320x50_restart', bottomRestartBannerRef.current, attempt);
        setBannerVisible(centerBannerRef.current, true);
        setBannerVisible(bottomRestartBannerRef.current, true);
        setBannerVisible(bottomGameplayBannerRef.current, false);
    }, [centerBannerRef, bottomRestartBannerRef, bottomGameplayBannerRef, setBannerVisible, updateAd]);

    useEffect(() => {
        addEventListener('OnResultShow', handleOnResultShow);

        return () => {
            console.log('clear effect ResultShow');
            removeEventListener('OnResultShow', handleOnResultShow)
        };
    }, [addEventListener, removeEventListener, handleOnResultShow]);
}

/**
 * hook that fires when game result screen is hiding,
 * handler gets current game attempt as parameter and
 * hides result screen ad banners
 *
 * @param centerBannerRef: RefObject<HTMLDivElement>
 * @param bottomRestartBannerRef: RefObject<HTMLDivElement>
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useResultHide = (centerBannerRef, bottomRestartBannerRef, addEventListener, removeEventListener) => {
    const { setBannerVisible } = useAdService();

    const handleOnResultHide = useCallback((attempt) => {
        console.log('useResultHide', {attempt});

        setBannerVisible(centerBannerRef.current, false);
        setBannerVisible(bottomRestartBannerRef.current, false);
    }, [centerBannerRef, bottomRestartBannerRef, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnResultHide', handleOnResultHide);

        return () => {
            console.log('clear effect ResultHide');
            removeEventListener('OnResultHide', handleOnResultHide)
        };
    }, [addEventListener, removeEventListener, handleOnResultHide]);
}

export const usePauseShow = (
    centerBannerRef,
    bottomPauseBannerRef,
    bottomGameplayBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnPauseShow = useCallback((attempt) => {
        console.log('usePauseShow', {attempt});

        updateAd('snakecolorbrake-com_300x250_pause', centerBannerRef.current, attempt);
        updateAd('snakecolorbrake-com_320x50_pause', bottomPauseBannerRef.current, attempt);
        setBannerVisible(centerBannerRef.current, true);
        setBannerVisible(bottomPauseBannerRef.current, true);
        setBannerVisible(bottomGameplayBannerRef.current, false);
    }, [centerBannerRef, bottomPauseBannerRef, bottomGameplayBannerRef, setBannerVisible, updateAd]);

    useEffect(() => {
        addEventListener('OnPauseShow', handleOnPauseShow);

        return () => {
            console.log('clear effect PauseShow');

            removeEventListener('OnPauseShow', handleOnPauseShow);
        };
    }, [addEventListener, removeEventListener, handleOnPauseShow]);
}

export const usePauseHide = (
    centerBannerRef,
    bottomPauseBannerRef,
    bottomGameplayBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnPauseHide = useCallback((attempt) => {
        console.log('usePauseHide', {attempt});

        updateAd('snakecolorbrake-com_320x50_gameplay', bottomGameplayBannerRef.current, attempt);
        setBannerVisible(centerBannerRef.current, false);
        setBannerVisible(bottomPauseBannerRef.current, false);
        setBannerVisible(bottomGameplayBannerRef.current, true);
    }, [centerBannerRef, bottomPauseBannerRef, bottomGameplayBannerRef, updateAd, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnPauseHide', handleOnPauseHide);

        return () => {
            console.log('clear effect PauseHide');
            removeEventListener('OnPauseHide', handleOnPauseHide)
        };
    }, [addEventListener, removeEventListener, handleOnPauseHide]);
}

/**
 * hook fires on game background change,
 * send current game background color,
 * set game container background color with matching game background color (you can add any additional logic into handleOnColorChange)
 *
 * @param addEventListener: () => void (add event listener functions from the Unity Context)
 * @param removeEventListener: () => void (remove event listener functions from the Unity Context)
 */
export const useColorChange = (addEventListener, removeEventListener) => {
    const [gameBgColor, setGameBgColor] = useState('#FFFFFF');

    const handleOnColorChange = useCallback((color) => {
        setGameBgColor(gameContainerBg[color])
    }, [setGameBgColor]);

    useEffect(() => {
        addEventListener('OnColorChange', handleOnColorChange);

        return () => removeEventListener('OnColorChange', handleOnColorChange);
    }, [addEventListener, removeEventListener, handleOnColorChange]);

    return gameBgColor;
}
