import React, { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Helmet } from "react-helmet";
import { environment } from "App/Helpers/enviroments";
import OneSignal from "react-onesignal";
import { oneSignalConfig } from "../../config";
import AdService from "App/Services/AdService";
import Game from "./components/Game";

const GameContainer = ({ gameAnalytics, sharpstarService, rid, offerId, adInterval, pushPrompt }) => {
    useEffect(() => {
        !!pushPrompt && initOneSignal();
    }, [])

    const initOneSignal = () => {
        OneSignal.init(oneSignalConfig)
            .then(() => {
                environment() !== 'production' &&
                console.info(
                    '%cInited OneSignal',
                    'background: #f3aa4e; color: #094205; font-weight: 900'
                );

                OneSignal.on('subscriptionChange', (subscribed) => {
                    subscribed && OneSignal.getUserId()
                        .then(async (playerId) => {
                            try {
                                const offer = offerId || localStorage.getItem('offerId');

                                await sharpstarService.createPushLead(playerId, offer, rid);
                            } catch (e) {
                                Sentry.captureException(e);
                            }
                        });
                })

                OneSignal.on('notificationPermissionChange', (permissionChange) => {
                    const permission = permissionChange.to;

                    localStorage.setItem('push-allowed', permission);
                    gameAnalytics.sendDesignEvent(`Push ${permission.charAt(0).toUpperCase() + permission.slice(1)}`)
                });
            })
            .catch((e) => {
                Sentry.captureException(e);
            })
    }

    return (
        <>
            <Helmet>
                <script async src="//api.adinplay.com/libs/aiptag/pub/RYM/snakecolorbrake.com/tag.min.js"></script>
            </Helmet>
            <AdService analyticsService={gameAnalytics} adInterval={adInterval}>
                <Game gameAnalytics={gameAnalytics} pushPrompt={pushPrompt} />
            </AdService>
        </>
    )
};

export default GameContainer;
