import React, { useEffect } from 'react';
import * as Sentry from "@sentry/browser";
import thumbUp from '../../assets/emoji-like.svg';
import './styles.scss';

const PushConsentModal = ({ setVisibility, analytics }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => document.body.style.overflow = 'unset';
    }, []);

    const allowOnClickHandler = () => {
        setVisibility(false);
        analytics.sendDesignEvent('Push Allow');

        try {
           OneSignal.showNativePrompt();
        } catch (e) {
            Sentry.captureException(
                new TypeError("OneSignal doesn't exist or isn't supported")
            );
        }
    }

    const continueOnClickHandler = () => {
        setVisibility(false);
        analytics.sendDesignEvent('Push Continue');
    }

    return (
        <div className={"modal-push_overlay"}>
            <div className={"modal-push_content"}>
                <div className={"modal-push_title"}>Allow to Get FREE games and updates!</div>
                <div onClick={allowOnClickHandler} className={"modal-push_button-allow"}>
                    Allow <img src={thumbUp} width={25} height={25} alt="thumb up emoji" className={"modal-push_button-emoji"}/>
                </div>
                <div onClick={continueOnClickHandler} className={"modal-push_button-continue"}>
                    Continue to game
                </div>
            </div>
        </div>
    )
};

export default PushConsentModal;
