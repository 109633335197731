export const setBodyOverflow = ({ overflow }) => {
    document.documentElement.style.setProperty('--overflow', overflow);
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

/**
 * check if more than N minutes pass from lass call
 *
 * @param prevTime: Date(required)
 * @param minutes: number(required)
 *
 * @return boolean
 */
export const hasElapsedMoreThanNMinutes = (prevTime, minutes) => {
    if (!prevTime) return false;

    const millisecondsDif = Math.abs(new Date() - prevTime);
    const minutesDif = millisecondsDif / (1000 * 60);

    return minutesDif > minutes;
}

/**
 * Checks whether an ad preroll should be shown based on an ad interval value
 *
 * @param curLevel: number(required)
 * @param adInterval: number(required)
 *
 * @return boolean;
 */
export const shouldDisplayPreroll = (curLevel, adInterval) => {
    if (adInterval === 0) {
        return true;
    }
    return (curLevel % (adInterval + 1)) === 0;
};

export const getSecondsDifference = (startDate, endDate) => {
    return +(Math.abs(endDate - startDate)/1000).toFixed(2);
}
