import React from 'react';
import free1920 from "../../../../assets/free-1920.png";
import free1024 from "../../../../assets/free-1024.png";
import free576 from "../../../../assets/free-576.png";
import free414 from "../../../../assets/free-414.png";
import free320 from "../../../../assets/free-320.png";
import simple1920 from "../../../../assets/simple-1920.png";
import simple1024 from "../../../../assets/simple-1024.png";
import simple576 from "../../../../assets/simple-576.png";
import simple414 from "../../../../assets/simple-414.png";
import simple320 from "../../../../assets/simple-320.png";
import endless1920 from "../../../../assets/endless-1920.png";
import endless1024 from "../../../../assets/endless-1024.png";
import endless576 from "../../../../assets/endless-576.png";
import endless414 from "../../../../assets/endless-414.png";
import endless320 from "../../../../assets/endless-320.png";
import eyeCatching1920 from "../../../../assets/eye-catching-1920.png";
import eyeCatching1024 from "../../../../assets/eye-catching-1024.png";
import eyeCatching576 from "../../../../assets/eye-catching-576.png";
import eyeCatching414 from "../../../../assets/eye-catching-414.png";
import eyeCatching320 from "../../../../assets/eye-catching-320.png";
import './styles.scss';


const Features = () => {
    return (
        <section className={"container small"}>
            <div className={"wrapper features"}>
                <h2 className={"title"}>
                    <span className={"highlighted"}>Game </span>
                    Features
                </h2>
                <div className={"feature-items"}>
                    <div className={"features-wrapper"}>
                        <div className={"feature-item"}>
                            <picture className={"feature-img"}>
                                <source
                                    srcSet={free1920}
                                    media="(min-width: 1440px)"
                                />
                                <source
                                    srcSet={free1024}
                                    media="(min-width: 1024px)"
                                />
                                <source
                                    srcSet={free1920}
                                    media="(min-width: 768px)"
                                />
                                <source
                                    srcSet={free576}
                                    media="(min-width: 576px)"
                                />
                                <source
                                    srcSet={free414}
                                    media="(min-width: 414px)"
                                />
                                <source
                                    srcSet={free320}
                                    media="(min-width: 320px)"
                                />
                                <img
                                    src={free1920}
                                    alt="rhombus icon with play button in the middle"
                                />
                            </picture>
                            <h5 className={"feature-title"}>Free to play</h5>
                        </div>
                        <div className={"feature-item"}>
                            <picture className={"feature-img"}>
                                <source
                                    srcSet={simple1920}
                                    media="(min-width: 1440px)"
                                />
                                <source
                                    srcSet={simple1024}
                                    media="(min-width: 1024px)"
                                />
                                <source
                                    srcSet={simple1920}
                                    media="(min-width: 768px)"
                                />
                                <source
                                    srcSet={simple576}
                                    media="(min-width: 576px)"
                                />
                                <source
                                    srcSet={simple414}
                                    media="(min-width: 414px)"
                                />
                                <source
                                    srcSet={simple320}
                                    media="(min-width: 320px)"
                                />
                                <img
                                    src={simple1920}
                                    alt="rhombus icon with play button in the middle"
                                />
                            </picture>
                            <h5 className={"feature-title"}>Simple swipe control</h5>
                        </div>
                    </div>
                    <div className={"features-wrapper"}>
                        <div className={"feature-item"}>
                            <picture className={"feature-img"}>
                                <source
                                    srcSet={endless1920}
                                    media="(min-width: 1440px)"
                                />
                                <source
                                    srcSet={endless1024}
                                    media="(min-width: 1024px)"
                                />
                                <source
                                    srcSet={endless1920}
                                    media="(min-width: 768px)"
                                />
                                <source
                                    srcSet={endless576}
                                    media="(min-width: 576px)"
                                />
                                <source
                                    srcSet={endless414}
                                    media="(min-width: 414px)"
                                />
                                <source
                                    srcSet={endless320}
                                    media="(min-width: 320px)"
                                />
                                <img
                                    src={endless1920}
                                    alt="rhombus icon with play button in the middle"
                                />
                            </picture>
                            <h5 className={"feature-title"}>Endless gameplay in colorful locations</h5>
                        </div>
                        <div className={"feature-item"}>
                            <picture className={"feature-img"}>
                                <source
                                    srcSet={eyeCatching1920}
                                    media="(min-width: 1440px)"
                                />
                                <source
                                    srcSet={eyeCatching1024}
                                    media="(min-width: 1024px)"
                                />
                                <source
                                    srcSet={eyeCatching1920}
                                    media="(min-width: 768px)"
                                />
                                <source
                                    srcSet={eyeCatching576}
                                    media="(min-width: 576px)"
                                />
                                <source
                                    srcSet={eyeCatching414}
                                    media="(min-width: 414px)"
                                />
                                <source
                                    srcSet={eyeCatching320}
                                    media="(min-width: 320px)"
                                />
                                <img
                                    src={eyeCatching1920}
                                    alt="rhombus icon with play button in the middle"
                                />
                            </picture>
                            <h5 className={"feature-title"}>Eye-catching graphics and animations</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Features;