import React from 'react';
import useWindowDimensions from "../../../../hooks/useWindowDementions";
import Carousel from "nuka-carousel";
import control from '../../../../assets/control.png';
import challenging from '../../../../assets/challenging.png';
import strategy from '../../../../assets/strategy.png';
import worldWide from '../../../../assets/worldwide.png';
import experience from '../../../../assets/experience.png';
import endless from '../../../../assets/endless.png';
import leftArrow from '../../../../assets/left-arrow.png';
import rightArrow from '../../../../assets/right-arrow.png';
import './styles.scss';

const Advantages = () => {
    const { width } = useWindowDimensions();

    return (
        <section className={"container advantages colored"}>
            <div className={"wrapper advantages"}>
                <h2 className={"title"}>
                    Why the hype around
                    <span className={"highlighted"}> Snake Color Brake?</span>
                </h2>
                <Carousel
                    className={'my-carousel'}
                    renderCenterLeftControls={() => ''}
                    renderCenterRightControls={() => ''}
                    slidesToShow={width > 1439 ? 3 : width > 767 ? 2 : 1}
                    cellSpacing={(width < 480 || width > 767) ? 40 : 160}
                    defaultControlsConfig={{ pagingDotsClassName: "pagination-dots" }}
                    renderBottomLeftControls={({ previousDisabled, previousSlide }) => (
                        <button className={"prev-btn"} onClick={previousSlide} disabled={previousDisabled}>
                            <img src={leftArrow} width={13} height={24} alt="left arrow"/>
                        </button>
                    )}
                    renderBottomRightControls={({ nextDisabled, nextSlide }) => (
                        <button className={"next-btn"} onClick={nextSlide} disabled={nextDisabled}>
                            <img src={rightArrow} width={13} height={24} alt="right  arrow"/>
                        </button>
                    )}>
                    <div className={"card"}>
                        <div className={"card_img"}>
                            <img src={control} width={849} height={849} alt={""} />
                        </div>
                        <div className={"card_content"}>
                            <h4 className={"card_title"}>
                                <span className={"highlighted"}>Control </span>
                                over a snake
                            </h4>
                            <p className={"card_text"}>
                                This fast-paced gameplay gives players control over a snake of balls used to break through blocks and reach high scores.
                            </p>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card_img"}>
                            <img src={challenging} width={425} height={428} alt={""} />
                        </div>
                        <div className={"card_content"}>
                            <h4 className={"card_title"}>
                                <span className={"highlighted"}>Challenging </span>
                                game
                            </h4>
                            <p className={"card_text"}>
                                With the simple control mechanism, players can easily play, diving deep into a game that is challenging to master.
                            </p>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card_img"}>
                            <img src={strategy} width={849} height={849} alt={""} />
                        </div>
                        <div className={"card_content"}>
                            <h4 className={"card_title"}>
                                <span className={"highlighted"}>Strategic </span>
                                needed
                            </h4>
                            <p className={"card_text"}>
                                Levels increase in difficulty as players advance, creating a genuinely addictive game that requires a strategic mind.
                            </p>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card_img"}>
                            <img src={worldWide} width={849} height={849} alt={""} />
                        </div>
                        <div className={"card_content"}>
                            <h4 className={"card_title"}>
                                <span className={"highlighted"}>Worldwide </span>
                                distribution
                            </h4>
                            <p className={"card_text"}>
                                Compete with friends and players worldwide, earning the chance to rank on leaderboards and show off your mastery.
                            </p>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card_img"}>
                            <img src={experience} width={849} height={849} alt={""} />
                        </div>
                        <div className={"card_content"}>
                            <h4 className={"card_title"}>
                                <span className={"highlighted"}>Great </span>
                                user experience
                            </h4>
                            <p className={"card_text"}>
                                Attractive graphics and animations offer an engaging user experience that brings the game to life.
                            </p>
                        </div>
                    </div>
                    <div className={"card"}>
                        <div className={"card_img"}>
                            <img src={endless} width={427} height={427} alt={""} />
                        </div>
                        <div className={"card_content"}>
                            <h4 className={"card_title"}>
                                <span className={"highlighted"}>Endless </span>
                                gameplay
                            </h4>
                            <p className={"card_text"}>
                                Once you play, you’re hooked. With no end in sight, this game lasts as long as you do.
                            </p>
                        </div>
                    </div>
                </Carousel>
            </div>
        </section>
    )
};

export default Advantages;